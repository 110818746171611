<a
  mat-button
  class="nav-item"
  [ngClass]="{ 'child-item': isChild, 'root-active': parentActive }"
  [routerLink]="route"
  [routerLinkActive]="isChild ? 'child-active' : 'root-active'"
  (click)="onCollapseToggle()"
>
  <ng-content></ng-content>
  <span>{{ text }}</span>
</a>
<div
  class="submenu"
  [class.collapsed]="!isExpanded"
>
  <ng-content select="[submenu]"></ng-content>
</div>
