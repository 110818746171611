<nav class="navbar navbar-expand-sm"
     aria-label="">
  <div class="container-fluid px-24">
    <a class="company-name"
       [routerLink]="null">
      <app-image-renderer [imageName]="'PCS-icon.svg'"
                          [altText]="'PCS-Icon'" />
    </a>
    <app-general-search-form [role]="userRole"
                             [loading]="loading"
                             [filterGroup]="filterGroup"
                             [options]="filteredOptions"
                             [states]="statesList"
                             [providers]="providerList"
                             [administrators]="administratorList"
                             [industries]="industryList"
                             [products]="productList"
                             [lenders]="lendersList"
                             (navigateTo)="navigateItemTo($event)"
                             (applyFilter)="applyFilter()"
                             [hasNextPageLenders]="hasNextPageLenders"
                             [hasNextPageProviders]="hasNextPageProviders"
                             [hasNextPageAdministrators]="hasNextPageAdministrators"
                             (loadMoreLenders)="onLoadMoreLenders($event)"
                             (loadMoreProviders)="onLoadMoreProviders()"
                             (loadMoreAdministrators)="onLoadMoreAdministrators()"
                             (onPressedEnter)="onEnterSearch()"></app-general-search-form>
    <div class="d-flex">
      <div class="notifications-dropdown dropdown">
        <button type="button"
                #notificationTrigger="matMenuTrigger"
                [matMenuTriggerFor]="notificationMenu">
          <span class="material-icons-outlined notification-icon">notifications_none</span>
          <span *ngIf="notifications.length > 0"
                class="has-notifications"></span>
        </button>
        <mat-menu #notificationMenu="matMenu"
                  [class]="'notification-menu'"
                  [overlapTrigger]="false"
                  [xPosition]="'before'">
          <div class="dropdown-header">
            <app-notification-menu-title [title]="'Notifications'" />
          </div>
          <ul class="custom-scroll">
            <li *ngIf="notifications.length == 0">
              <span>You have no unread notifications at the moment.</span>
            </li>
            <li *ngFor="let notification of notifications | slice : 0 : 4">
              <app-notification-menu-item [title]="notification.title"
                                          [description]="notification.description"
                                          [date]="notification.dateTime | date:'shortDate'"
                                          [time]="notification.dateTime | date:'shortTime'"
                                          (click)="markAsReadAndNavigate(notification.id ,notification.referenceId, notification.entity)" />
            </li>

          </ul>

          <div class="dropdown-footer">
            <a [routerLink]="'notifications-list'">View All Notifications</a>
          </div>
        </mat-menu>
      </div>

      <div class="user-dropdown dropdown">
        <a type="button"
           class="user-a"
           #userTrigger="matMenuTrigger"
           [matMenuTriggerFor]="userMenu">
          <span *ngIf="!imageProfile">
            {{ getFullName(userProfile$ | async) | initials | uppercase }}
          </span>
          <img class="user-profile-img" *ngIf="imageProfile" [src]="imageProfile" >
        </a>
        <mat-menu #userMenu="matMenu"
                  [class]="'user-menu'"
                  [overlapTrigger]="false"
                  [xPosition]="'before'">
          <div class="dropdown-menu">
            <div class="dropdown-header">
              <app-avatar-profile [fullName]="getFullName(userProfile$ | async)"
                                  [imgProfile]="imageProfile"
                                  [email]="(user$ | async)?.email"
                                  [config]="{ avatarSize: 'lg' }" />
            </div>
            <div class="h-separator"></div>
            <ul>
              <li>
                <a (click)="onResetPassword()" class="user-menu-item">
                  <span class="material-icons-outlined icon">lock</span>
                  <span class="text">Update password</span>
                </a>
              </li>
              <li>
                <a (click)="onProfile()" class="user-menu-item">
                  <span class="material-icons-outlined icon">person</span>
                  <span class="text">Edit account details</span>
                </a>
              </li>
              <li>
                <a (click)="onNotificationPreferences()"
                   class="user-menu-item">
                  <span class="material-icons-outlined icon">notifications_none</span>
                  <span class="text">Notifications preferences</span>
                </a>
              </li>
              <li>
                <a [routerLink]="null"
                   class="user-menu-item"
                   (click)="onLogout()">
                  <span class="material-icons-outlined icon">logout</span>
                  <span class="text">Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
</nav>
