import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import {
  debounceTime,
  filter,
  interval,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { TypeOfResult } from 'src/app/models/typeOfResult/typeOfResult';
import { Role } from 'src/app/models/user/role.model';
import { AdministratorService } from 'src/app/services/administrator.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { IndustriesService } from 'src/app/services/industries.service';
import { LenderOrganizationsService } from 'src/app/services/lender.organizations.service';
import { NotificationService } from 'src/app/services/notifications.service';
import { ProductsService } from 'src/app/services/products.service';
import { ProviderOrganizationsService } from 'src/app/services/provider.organizations.service';
import { StatesService } from 'src/app/services/states.service';
import { IUserProfile, UsersService } from 'src/app/services/users.service';
import { SearchService } from '../../../../services/search.service';
import { ISearchItem, ISearchResult } from './search.model';

@Component({
    selector: 'app-main-navigation',
    templateUrl: './main-navigation.component.html',
    styleUrls: ['./main-navigation.component.scss'],
    standalone: false
})
export class MainNavigationComponent implements OnInit {
  user$: Observable<User | null | undefined>;
  userProfile$: Observable<IUserProfile | null>;
  userRole: Role | undefined;
  userOrganization: string | undefined;
  notifications: any[] = [];

  filterGroup = new FormGroup({
    searchKey: new FormControl('', [Validators.minLength(4)]),
    statesIds: new FormControl(),
    lenderIds: new FormControl(),
    status: new FormControl(),
    submissionProgressId: new FormControl(),
    providerOrganizationId: new FormControl(),
    administratorId: new FormControl(),
    productId: new FormControl(),
    industryId: new FormControl(),
  });

  filteredOptions?: ISearchItem[];
  loading: boolean;
  private subscription: Subscription;

  statesList: any[] = [];
  providerList: any[] = [];
  administratorList: any[] = [];
  industryList: any[] = [];
  productList: any[] = [];
  lendersList: any[] = [];

  hasNextPageLenders = true;
  currentPageLenders = 1;
  hasNextPageProviders = true;
  currentPageProviders = 1;
  hasNextPageAdministrators = true;
  currentPageAdministrators = 1;
  pageSize = 20;

  pressedEnterOnSearch : boolean = false;

  @Input() imageProfile: string;
  @Output() openPreferences: EventEmitter<boolean> = new EventEmitter();
  @Output() openProfile: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private authService: AuthenticationService,
    public notificationService: NotificationService,
    private router: Router,
    private userService: UsersService,
    private searchService: SearchService,
    private statesService: StatesService,
    private providerService: ProviderOrganizationsService,
    private administratorService: AdministratorService,
    private industriesService: IndustriesService,
    private productsService: ProductsService,
    private lendersService: LenderOrganizationsService,
  ) {
    this.user$ = authService.user$;
    this.userProfile$ = userService.userProfile$;

    this.filterGroup.controls['searchKey'].valueChanges
      .pipe(
        filter((value) => this.validateInput(value)),
        tap(() => (this.loading = true)),
        debounceTime(2000),
        switchMap((value) => this._filter(value || '')),
        tap(() => (this.loading = false))
      )
      .subscribe((options: any) => {
        this.filteredOptions = options.data.flatMap(
          (optionGroup?: ISearchResult) => optionGroup?.items
        ) as ISearchItem[];
      });
  }

  ngOnInit(): void {
    this.userService.userRole$.subscribe((role) => {
      this.userRole = role;

      if(this.userRole != Role.Lender) {
        this.fetchLenders();
      }
    });

    this.fetchNotifications();

    this.getNotification1MinInterval();

    this.reloadNotifications();

    this.fetchStates();

    this.fetchProviders();

    this.fetchAdministrators();

    this.fetchIndustries();

    this.fetchProducts();

  }

  private fetchStates() {
    this.statesService.get().subscribe((response) => {
      if (!response.isError) {
        this.statesList = response.data;
      }
    });
  }

  private fetchProviders() {
    this.providerService
      .get(
        {
          page: this.currentPageProviders,
          pageSize: this.pageSize,
          isActive: true
        })
      .subscribe((response: any) => {
      if (!response.isError) {
        this.providerList = response.data.items;
        this.providerList.unshift({ id: 0, name: 'All' });
      }
    });
  }
  private fetchAdministrators() {
    this.administratorService
    .get(
      {
        page: this.currentPageAdministrators,
        pageSize: this.pageSize,
        sortOrder: 'asc',
        sortColumn: 'name',
        isActive: true
      }).subscribe((response: any) => {
      if (!response.isError) {
        this.administratorList = response.data.items;
        this.administratorList.unshift({ id: 0, name: 'All' });
      }
    });
  }
  private fetchIndustries() {
    this.industriesService.get().subscribe((response) => {
      if (!response.isError) {
        this.industryList = response.data;
        this.industryList.unshift({ id: 0, name: 'All' });
      }
    });
  }
  private fetchProducts() {
    this.productsService.get().subscribe((response: any) => {
      if (!response.isError) {
        this.productList = response.data.items;
        this.productList.unshift({ id: 0, name: 'All' });
      }
    });
  }
  private fetchLenders() {
    this.lendersService.get(
      {
        page: this.currentPageLenders,
        pageSize: this.pageSize,
        isActive: true
      }
    ).subscribe((response: any) => {
      if (!response.isError) {
        this.lendersList = response.data.items;
      }
    });
  }

  onLogout() {
    this.authService.logout();
  }

  getFullName(user: IUserProfile | null | undefined): string {
    let fullName = '';
    if (user?.firstName && user.lastName) {
      fullName = `${user.firstName} ${user.lastName}`;
    }
    return fullName;
  }

  private _filter(value: string): Observable<ISearchResult[]> {
    if (this.pressedEnterOnSearch) {
      this.pressedEnterOnSearch = false;
      return of([]);
    }

    if (!value) return of([]);

    const searchValue = value.toLowerCase();
    const filtersValue = this.filterGroup.value;

    let previousFilters = this.searchService.getFiltersValue();

    let filters = {
      ...previousFilters,
      searchKey: searchValue,
      status: filtersValue.status != 0 ? filtersValue.status : undefined,
      providerOrganizationId: filtersValue.providerOrganizationId
        ? filtersValue.providerOrganizationId
        : undefined,
      statesIds:
          filtersValue.statesIds?.length > 0 ? filtersValue.statesIds.split(',') : null,
      lenderIds:
        filtersValue.lenderIds?.length > 0 ? filtersValue.lenderIds.split(',') : null,
      administratorId: filtersValue.administratorId
        ? filtersValue.administratorId
        : undefined,
      productId: filtersValue.productId ? filtersValue.productId : undefined,
      industryId: filtersValue.industryId ? filtersValue.industryId : undefined,
      fullSubmission: (filtersValue.submissionProgressId != null && filtersValue.submissionProgressId != 0) ? (filtersValue.submissionProgressId == 2) : undefined,
    };

    this.searchService.updateFilters(filters);

    return this.searchService.generalSearch(undefined, filters);
  }


  hasMinLength(value: any) {
    return value ? value.length > 3 : false;
  }

  validateInput(value: any) {
    if (this.hasMinLength(value)) {
      return true;
    }
    this.filteredOptions = [];
    return false;
  }

  navigateItemTo(event: { id?: number; typeOfResult?: TypeOfResult }) {
    if (event.typeOfResult == TypeOfResult.FormSubmission) {
      this.onViewFormSubmission(event.id);
    } else if (event.typeOfResult == TypeOfResult.FormConsolidation) {
      this.onViewFormConsolidation(event.id);
    } else if (event.typeOfResult == TypeOfResult.MBRRequest) {
      this.onViewMBR(event.id);
    } else if (event.typeOfResult == TypeOfResult.ExternalApproval) {
      this.onViewExternalApproval(event.id);
    } else {
      this.onViewMarketingMaterial(event.id);
    }
  }

  markAsReadAndNavigate(
    notificationId?: number,
    referenceId?: number,
    entity?: TypeOfResult
  ) {
    this.notificationService.markAsRead([notificationId]).subscribe((_) => {
      this.notificationService.reloadBellNotifications.next(true);
      this.router.navigate([this.notificationService.navigate(entity, referenceId)]);
    });
  }

  onViewFormSubmission(id?: number) {
    if (this.userRole === Role.Provider) {
      this.router.navigate([`/provider/form-submission/details/${id}`]);
    } else if (this.userRole === 'Lender') {
      this.router.navigate([`/lender/form-submission/details/${id}`]);
    } else {
      this.router.navigate([`/admin/form-submission/details/${id}`]);
    }
  }

  onViewMBR(id?: number) {
    if (this.userRole === Role.Lender) {
      this.router.navigate([`/lender/mbr-request/details/${id}`]);
    } else {
      this.router.navigate([`/admin/mbr-request/details/${id}`]);
    }
  }

  onViewExternalApproval(id?: number) {
    if (this.userRole === Role.Lender) {
      this.router.navigate([`/lender/external-approval/details/${id}`]);
    } else {
      this.router.navigate([`/admin/external-approval/details/${id}`]);
    }
  }

  onViewFormConsolidation(id?: number) {
    if (this.userRole === Role.Provider) {
      this.router.navigate([`/provider/form-consolidation/details/${id}`]);
    } else {
      this.router.navigate([`/admin/form-consolidation/details/${id}`]);
    }
  }

  onViewMarketingMaterial(id?: number) {
    if (this.userRole === Role.Provider) {
      this.router.navigate([`/provider/marketing-material/details/${id}`]);
    } else if (this.userRole === 'Lender') {
      this.router.navigate([`/lender/marketing-material/details/${id}`]);
    } else {
      this.router.navigate([`/admin/marketing-material/details/${id}`]);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onNotificationPreferences() {
    this.openPreferences.emit(true);
  }
  onProfile() {
    this.openProfile.emit(true);
  }

  private reloadNotifications() {
    this.notificationService.reloadBellNotifications.subscribe(() => {
      this.fetchNotifications();
    });
  }

  private getNotification1MinInterval() {
    this.subscription = interval(60000)
      .pipe(switchMap(() => this.notificationService.getLimited()))
      .subscribe((response: any) => {
        if (!response.isError) {
          this.notifications = response.data.items;
        }
      });
  }

  private fetchNotifications() {
    this.notificationService.getLimited().subscribe((response: any) => {
      if (!response.isError) {
        this.notifications = response.data.items;
      }
    });
  }

  applyFilter() {
    this.filterGroup.controls['searchKey'].setValue(
      this.filterGroup.controls['searchKey'].value
    );
  }

  onLoadMoreLenders(event: any) {
    const { searchKey, reset } = event;

    this.lendersService.get(
      {
        page: reset ? 1 : this.currentPageLenders + 1,
        pageSize: this.pageSize,
        isActive: true,
        searchKey: searchKey
      }
    ).subscribe((response: any) => {
      const newItems = response.data.items.map((lender: any) => {
        return {
          id: lender.id,
          name: lender.name,
        };
      });

      if(reset) {
        this.lendersList = newItems;
      } else {
        this.lendersList = [
          ...this.lendersList,
          ...newItems
        ];
      }
      this.hasNextPageLenders = response.data.hasNextPage;
      this.currentPageLenders = response.data.page;
    });
  }

  onLoadMoreProviders() {
    this.providerService.get(
      {
        page: this.currentPageProviders + 1,
        pageSize: this.pageSize,
        isActive: true
      }
    ).subscribe((response: any) => {
      const newItems = response.data.items.map((provider: any) => {
        return {
          id: provider.id,
          name: provider.name,
        };
      });

      this.providerList = [
        ...this.providerList,
        ...newItems
      ];

      this.hasNextPageProviders = response.data.hasNextPage;
      this.currentPageProviders = response.data.page;
    });
  }

  onLoadMoreAdministrators() {
    this.administratorService.get(
      {
        page: this.currentPageAdministrators + 1,
        pageSize: this.pageSize,
        sortColumn: 'name',
        isActive: true
      }
    ).subscribe((response: any) => {
      const newItems = response.data.items.map((administrator: any) => {
        return {
          id: administrator.id,
          name: administrator.name,
        };
      });

      this.administratorList = [
        ...this.administratorList,
        ...newItems
      ];

      this.hasNextPageAdministrators = response.data.hasNextPage;
      this.currentPageAdministrators = response.data.page;
    });
  }

  onEnterSearch(){
    this.pressedEnterOnSearch = true;
  }

  onResetPassword() {
    this.userService.resetUserPassword().subscribe();
  }
}
