<div class="pro-card dashboard-card">
  <div class="dashboard-card-header">
    <a [routerLink]="headerRouteLink">
      <h2 class="pro-card-title">{{ title }}</h2>
    </a>
    <span *ngIf="total" class="count">{{ total }}</span>
  </div>
  <div class="dashboard-card-content">
    <ng-container *ngIf="!loading;">
      <ng-container *ngIf="listData && listData.length > 0; else emptyData">
        <app-card-dashboard-item
          *ngFor="let info of listData | slice : 0 : limitItems; let i = index"
          [infoContent]="info.list ? info.list : []"
          [statusContent]="info.status ? info.status : []"
          [flex]="modeFlex"
          [badgeSize]="modeBadge"
          (goToDetail)="onGoDetail(info.id)"
        />
      </ng-container>
      <ng-template #emptyData>
        <div class="pro-card-empty">
          There are no {{ title }} to display
        </div>
      </ng-template>
    </ng-container>

    <div class="pro-card-empty" *ngIf="loading" >
      <app-loader />
    </div>
  </div>


</div>
