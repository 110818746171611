import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../../loader/loader.component';

@Component({
    selector: 'app-card-stats',
    imports: [CommonModule, LoaderComponent],
    templateUrl: './card-stats.component.html',
    styleUrls: ['./card-stats.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardStatsComponent {
  @Input() title: string;
  @Input() value?: number | null = 0;
  @Input() loading: boolean = false;
}
