import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { APIResponseModel } from '../models/api-response-model';
import { catchError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProviderInvoicesService extends BaseService {

  private apiUrl = `invoices`;
  private httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(http, `invoices`);
  }

  markAsPaid(id: number): Observable<APIResponseModel<any[]>> {
    return this.http
      .post<APIResponseModel<any[]>>(
        `${this.baseUrl}/${id}/payment`,
        {}
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, []);
        })
      );
  }
}
