import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-confirmation-dialog',
    templateUrl: './delete-confirmation-dialog.component.html',
    styleUrls: ['./delete-confirmation-dialog.component.scss'],
    standalone: false
})
export class DeleteConfirmationDialogComponent implements OnInit {
  title: string;
  message: string;
  actionText: string;

  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }
  ngOnInit(): void {
    this.title = this.data.title;
    this.message = this.data.message;
    this.actionText = this.data.actionText;
  }

  closeDialog(){
    this.dialogRef.close(false);
  }
}
