import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { debounceTime, distinctUntilChanged, fromEvent, tap } from 'rxjs';
import { MatSelectAllComponent } from './mat-select-all/mat-select-all.component';
import { LoaderComponent } from '../loader/loader.component';
import { ISelect } from './mat-multiselect.component';

@Component({
    selector: 'app-mat-multi-select-pagination',
    templateUrl: './mat-multiselect-pagination.component.html',
    styleUrls: ['./mat-multiselect-pagination.component.scss'],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatSelectAllComponent,
        LoaderComponent,
    ]
})
export class MatMultiSelectPaginationComponent implements OnInit, AfterViewInit, OnChanges {
  @Input('control') selectFormControl: AbstractControl;
  @Input() options: ISelect[] = [];
  @Input() disabled: boolean = false;
  @Input() requiredMessage: string = '';
  @Input() innerLabel: string = '';
  @Input() size: string = 'lg';
  @Input() hasNextPage: boolean = true;

  @Output() loadMore = new EventEmitter<{ searchKey: string; reset?: boolean }>();

  @ViewChild('search') searchTextBox: ElementRef;
  @ViewChild('optionsContainer') optionsContainer: ElementRef;

  searchTextboxControl = new FormControl();
  filteredOptions: ISelect[] = [];
  selectedValues: ISelect[] = [];
  loading = false;

  ngOnInit() {
    this.searchTextboxControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((searchKey) => {
          this.filteredOptions = this._filter(searchKey || '');
          this.resetSearch();
        })
      )
      .subscribe();

    this.setSelectedValues();

    this.getFormControl().valueChanges.subscribe(() => this.setSelectedValues());
  }

  ngAfterViewInit() {
    if (this.optionsContainer) {
      fromEvent(this.optionsContainer.nativeElement, 'scroll')
        .pipe(
          debounceTime(300),
          tap(() => {
            if (this.shouldLoadMore()) {
              this.loadMore.emit({ searchKey: this.searchTextboxControl.value || '' });
            }
          })
        )
        .subscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      const filteredList = this._filter(this.searchTextboxControl.value || '');

      const uniqueSelectedValues = this.selectedValues.filter(
        (selected) => !filteredList.some((option) => option.id === selected.id)
      );

      this.filteredOptions = [...uniqueSelectedValues, ...filteredList];

      this.loading = false;
    }
  }



  private _filter(name: string): ISelect[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }



  private resetSearch() {
    this.loading = true;
    this.options = [];
    this.loadMore.emit({ searchKey: this.searchTextboxControl.value || '', reset: true });
  }

  private shouldLoadMore(): boolean {
    if (!this.hasNextPage || this.loading) return false;

    const container = this.optionsContainer.nativeElement;
    return container.scrollTop + container.clientHeight >= container.scrollHeight - 100;
  }

  private setSelectedValues() {
    const currentValue = this.selectFormControl.value || [];
    this.selectedValues = currentValue;
  }


  getFormControl(): FormControl {
    return this.selectFormControl as FormControl;
  }

  openedChange(opened: boolean) {
    if (opened) {
      this.searchTextBox.nativeElement.focus();
      this.setSelectedValues();

    }

  }


  getValuesSample(): string {
    return this.selectFormControl.value
      ? this.selectFormControl.value
          .slice(0, 3)
          .map((value: ISelect) => value.name)
          .join(', ')
      : '';
  }
}
