<mat-drawer-container hasBackdrop>
  <mat-drawer
    autoFocus="false"
    #drawer
    mode="over"
    position="end"
    (closed)="closeRightSidebar()"
  >
  <app-right-sidebar-container
      [title]="'Upload Invoice'"
      [footerSubmitCustomLabel]="isEditing ? 'Update' : 'Add'"
      (cancel)="closeRightSidebar()"
      (submit)="onSubmit()"
      [disableSubmit]="invoiceForm.invalid || emptyAttachment()"
      [loading]="isLoading"
    >
      <div class="document-sidebar-body">
        <form
          class="animate-labels-form document-sidebar-content"
          [formGroup]="invoiceForm"
        >
          <div class="custom-form-field">
            <label for="invoiceDate"
              >Invoice Date<span class="required">*</span></label
            >
            <mat-form-field appearance="outline" class="custom-field">
              <input
                matInput
                [matDatepicker]="invoiceDatePicker"
                name="invoiceDate"
                formControlName="invoiceDate"
              />
              <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker">
                <mat-icon
                  class="icon-btn"
                  matDatepickerToggleIcon
                  fontSet="material-icons-outlined"
                  >calendar_today</mat-icon
                >
              </mat-datepicker-toggle>
              <mat-datepicker #invoiceDatePicker></mat-datepicker>
              <mat-error
                *ngIf="invoiceForm.controls['invoiceDate'].errors?.['required']"
              >
                Please select Invoice Date
              </mat-error>
            </mat-form-field>
            <app-upload-documents
              [files]="filesToUpdate"
              (uploadingEvent)="uploadingEventHandler($event)"
              (onFileRemove)="onFileRemoveHandler($event)"
              [isMulti]="false"
              #invoiceDocuments>
            </app-upload-documents>
            <div *ngIf="emptyAttachment()">
              <div class="error">Please upload at least one invoice.</div>
            </div>
          </div>
        </form>
      </div>
    </app-right-sidebar-container>
  </mat-drawer>
  <mat-drawer-content>
    <div class="pcs-container">
      <div class="pcs-header mb-20">
        <h3 class="pcs-title">Invoices</h3>
        <div class="card-actions" *ngIf="isAdmin()">
          <button
            (click)="openRightSidebar(false)"
            mat-stroked-button
            color="primary"
            class="primary"
            type="button"
          >
            <mat-icon class="icon-btn">upload</mat-icon>
            Upload new Invoice
          </button>
        </div>
      </div>
      <ng-container *ngIf="isLoading; else templateGridInvoices">
        <div class="loader-container">
          <app-loader />
        </div>
      </ng-container>
      <ng-template #templateGridInvoices>
        <ng-container
          *ngIf="providerInvoices.length > 0; else templateEmptyInvoices"
        >
          <div class="invoices-grid">
            <div class="pro-card invoice-card" *ngFor="let invoice of providerInvoices">
              <div class="invoice-header">
                <a class="invoice-copies" (click)="downloadDocument(invoice.document)">
                  <mat-icon fontSet="material-icons-outlined"
                    >insert_drive_file</mat-icon
                  >
                  <span class="invoice-copy">{{ invoice?.document?.name}}</span>
                </a>
                <div class="invoice-expiration-date-container">
                  <mat-icon>schedule</mat-icon>
                  <span class="invoice-expiration-date">Date: {{ invoice?.invoiceDate | date: 'MM/dd/yy'}}</span>
                </div>
              </div>

              <div class="invoice-body">
                <div class="invoice-status">
                  <span>Status: </span>
                  <app-status-badge
                    class="status-badge"
                    [text]="invoice.status"
                    [status]="invoice.status == 'Unpaid' ? 'info' :'success'"
                    [size]="'large'"
                  />
                </div>
                <div class="invoice-actions">
                  <button
                    mat-stroked-button
                    *ngIf="isAdmin()"
                    (click)="deleteInvoice(invoice)"
                    [matTooltip]="'Delete'"
                    class="danger outer icon-only"
                  >
                    <mat-icon
                      class="icon-btn card-action-icon"
                      fontSet="material-icons-outlined"
                      >delete</mat-icon
                    >
                  </button>
                  <button
                    mat-stroked-button
                    (click)="markAsPaid(invoice)"
                    [matTooltip]="'Mark as paid'"
                    class="success outer icon-only"
                    *ngIf="invoice.status == 'Unpaid' && isAdmin()"
                  >
                    <mat-icon
                      class="icon-btn card-action-icon"
                      fontSet="material-icons-outlined"
                      >done</mat-icon
                    >
                  </button>
                  <button
                    mat-stroked-button
                    [matTooltip]="'Download'"
                    (click)="downloadDocument(invoice.document)"
                    class="primary outer icon-only"
                  >
                    <mat-icon
                      class="icon-btn card-action-icon"
                      fontSet="material-icons-outlined"
                      >download</mat-icon
                    >
                  </button>
                  <button
                    mat-stroked-button
                    (click)="uploadInvoice(invoice)"
                    [matTooltip]="'Upload invoice'"
                    class="primary icon-only fill"
                    *ngIf="isAdmin()"
                  >
                    <mat-icon
                      class="icon-btn card-action-icon"
                      fontSet="material-icons-outlined"
                      >upload_file_outlined</mat-icon
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator
            #paginator
            [selectConfig]="{ panelClass: 'mat-paginator-select-options' }"
            [length]="this.totalCount"
            [pageIndex]="this.currentPage"
            [pageSize]="this.pageSize"
            [pageSizeOptions]="pageSettings.pageSizeOptions"
            (page)="handlePageEvent($event)"
            aria-label="Select page"
          >
          </mat-paginator>
        </ng-container>
        <ng-template #templateEmptyInvoices>
          <div class="pro-card invoice-card">
            <p class="invoice-card-empty-text">No Invoices yet</p>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
