import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
  selector: 'app-grid-document',
  templateUrl: './grid-document.component.html',
  styleUrls: ['./grid-document.component.scss'],
  standalone: false
})
export class GridDocumentComponent implements ICellRendererAngularComp
{

  refresh(params: ICellRendererParams<any, any, any>): boolean {
      return false;
  }

  agInit(params: any): void {
      this.attachments = params.getValue();
  }

  constructor(private authService: AuthenticationService, private documentService: DocumentsService) {
  }

  public attachments: any;

  downloadFile(file: any) {
    this.documentService.downloadDocument(file);
  }

  getDocType(file: any): string {
    return file.name.split('.')[file.name.split('.').length - 1];
  }
}
