<mat-drawer-container hasBackdrop>
  <mat-drawer autoFocus="false" #drawer mode="over" position="end" (closed)="close()">
    <app-right-sidebar-container
      [title]="(isEditing ? 'Update' : 'Create') + ' Provider'"
      [footerSubmitCustomLabel]="isEditing ? 'Update' : 'Create'"
      (cancel)="close()"
      (submit)="onSubmit()"
      [disableSubmit]="form.invalid || isLoading"
    >
      <form [formGroup]="form" class="provider-content">
        <div class="custom-form-field">
          <label for="email">Email address<span class="required">*</span></label>
          <mat-form-field appearance="outline" class="custom-field">
            <input formControlName="email" matInput name="email" />
            <mat-error *ngIf="form.controls['email'].errors?.['required']">
              Enter email address
            </mat-error>
            <mat-error *ngIf="form.controls['email'].errors?.['email']">
              Enter valid email address
            </mat-error>
            <mat-error *ngIf="form.controls['email'].errors?.['entityExists']">
              User already exists with this email
            </mat-error>
          </mat-form-field>
        </div>
        <div class="custom-form-field">
          <label for="firstName">First Name<span class="required">*</span></label>
          <mat-form-field appearance="outline" class="custom-field">
            <input formControlName="firstName" matInput name="firstName"/>
            <mat-error *ngIf="form.controls['firstName'].errors?.['required']">
              Enter First Name
            </mat-error>
          </mat-form-field>
        </div>
        <div class="custom-form-field">
          <label for="lastName">Last Name<span class="required">*</span></label>
          <mat-form-field appearance="outline" class="custom-field">
            <input formControlName="lastName" matInput name="lastName"/>
            <mat-error *ngIf="form.controls['lastName'].errors?.['required']">
              Enter Last Name
            </mat-error>
          </mat-form-field>
        </div>
        <div class="custom-form-field">
          <label for="phoneNumber">Phone Number<span class="required">*</span></label>
          <mat-form-field appearance="outline" class="custom-field">
            <input formControlName="phoneNumber" matInput name="phoneNumber"/>
            <mat-error *ngIf="form.controls['phoneNumber'].errors?.['required']">
              Enter Phone Number
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </app-right-sidebar-container>
  </mat-drawer>
  <mat-drawer-content>
    <div class="pcs-container">
      <div class="pcs-header">
        <h3 class="pcs-title">{{organization?.name || '-'}}</h3>
      </div>
        <br />

        <div class="card-body">
          <app-row-details-data [data]="descriptionRowData" ></app-row-details-data>

          <br />
          <div>
            <mat-tab-group class="provider-tabs" mat-stretch-tabs="false" mat-align-tabs="start">
              <mat-tab label="Users">
                <ng-template matTabContent>
                  <div class="pcs-container">
                    <div class="pcs-header">
                      <h3 class="pcs-title">Users</h3>
                        <div class="card-actions">
                          <button (click)="openProviderSidebar()"
                            mat-flat-button
                            color="primary"
                            type="button"><mat-icon>add</mat-icon> Create</button>
                      </div>
                    </div>
                    <div class="pro-card">
                      <app-ag-grid-wrapper
                        [data]="rowData$"
                        [columns]="columnDefs"
                        (ready)="onGridReady($event)"
                      />
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab *ngFor="let documentType of documentTypes" [label]="documentType.name">
                <ng-template matTabContent>
                  <app-provider-documents
                  [organizationId]="this.organizationId"
                  [documentType]="documentType"
                  [states]="states"
                  [products]="products"
                  >
                  </app-provider-documents>
                </ng-template>
              </mat-tab>
              <mat-tab label="Invoices">
                <ng-template matTabContent>
                  <app-provider-invoices
                  [role]="userRole"
                  [organizationId]="this.organizationId"
                  ></app-provider-invoices>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
        </div>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
