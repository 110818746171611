import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

export interface IData {
  title?: string,
  description?: string,
  customDescription?: {
    class?: string,
    icon?: string
    description?: string
  }
}
@Component({
    selector: 'app-row-details-data',
    imports: [CommonModule, MatIconModule],
    templateUrl: './row-details-data.component.html',
    styleUrls: ['./row-details-data.component.scss']
})
export class RowDetailsDataComponent {
  @Input() data: IData[];
}
