import { Component, Input } from '@angular/core';
import { Role } from 'src/app/models/user/role.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
    selector: 'app-submission-detail-card',
    templateUrl: './submission-detail-card.component.html',
    styleUrls: ['./submission-detail-card.component.scss'],
    standalone: false
})
export default class SubmissionCetailCardComponent {

  role: Role | undefined;

  constructor(public usersService: UsersService) {
    usersService.userRole$.subscribe(role => this.role = role);
  }

  joinedStates = () : string => {
    return Array.from(this.submissionData.states.map((s: any) => s.name)).join(', ');
  };

  @Input() submissionData: any;
  @Input() lenderData!: any


}