import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  imports: [CommonModule, RouterModule],
  standalone: true

})
export class NavItemComponent {
  @Input() route?: string;
  @Input() text: string;
  @Input() collapseId?: string;
  @Input() isChild = false;
  @Input() parentActive = false;

  isExpanded = false;

  onCollapseToggle(): void {
    if (this.collapseId) {
      this.isExpanded = !this.isExpanded;
    }
  }
}
