import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { APIResponseModel } from '../models/api-response-model';
import { removeUndefinedFormsAttributes } from '../utils/utils';
import { BaseService } from './base-service';
import { INavigation } from './form-submissions.service';
import { ISearchFilter } from './search.service';

export interface IMbrRequestSearchParameters extends ISearchFilter{
  sortColumn?: string,
  sortOrder?: 'asc' | 'desc',
  backUrl?: string,
}

@Injectable({
  providedIn: 'root'
})

export class MBRRequestsService extends BaseService {
    private localStorageKey = 'mbrRequest_searchParameters';
    private mbrRequestFiltersForNavigation$: BehaviorSubject<IMbrRequestSearchParameters>;

  constructor(http: HttpClient) {
    super(http, `mbrrequests`);
    const savedState = localStorage.getItem(this.localStorageKey);
    this.mbrRequestFiltersForNavigation$ = new BehaviorSubject<IMbrRequestSearchParameters>(
      savedState ? JSON.parse(savedState) : this.getDefaultState()
    );
  }

  private getDefaultState(): IMbrRequestSearchParameters {
    return {
      sortColumn: 'submissionDate',
      sortOrder: 'desc',
    };
  }

  getMbrRequestFiltersForNavigation() {
    return this.mbrRequestFiltersForNavigation$.asObservable();
  }

  getMbrRequestFiltersForNavigationValue(){
    return this.mbrRequestFiltersForNavigation$.getValue();
  }

  setMbrRequestFiltersForNavigation(newState: Partial<IMbrRequestSearchParameters>) {
    removeUndefinedFormsAttributes(newState);
    this.mbrRequestFiltersForNavigation$.next(newState);
    localStorage.setItem(this.localStorageKey, JSON.stringify(newState));
  }

  deleteMbrRequestFiltersForNavigation(){
    localStorage.removeItem(this.localStorageKey);
  }
  
  uploadDocument(id: string, file: File): Observable<APIResponseModel<any>> {

    let formData = new FormData();
    formData.append(file.name, file);

    return this.http.post<APIResponseModel<any>>(`${this.baseUrl}/documentupload/${id}`, formData)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }

  getComments(id: any): Observable<APIResponseModel<any[]>> {
    return this.http.get<APIResponseModel<any[]>>(`${this.baseUrl}/${id}/comments`)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, []);
        })
    );
  }

  updateStatus(id: string, action: string, data: any): Observable<APIResponseModel<any>> {

    return this.http.post<APIResponseModel<any>>(`${this.baseUrl}/${id}/${action}`, data)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }


  replyComment(
    formId: string,
    commentId: string = '',
    data: any
  ): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/${formId}/comments/${commentId}/reply`, data)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  
  getNavigation(formId: number, filters: any): Observable<APIResponseModel<INavigation>> {
    return this.http
      .get<APIResponseModel<INavigation>>(`${this.baseUrl}/${formId}/navigation`, filters ? { params: {...filters } } : { params: {} })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, []);
        })
      );
  }
}
