import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Role } from 'src/app/models/user/role.model';
import { ISelect } from '../../components/mat-multiselect/mat-multiselect.component';
import { MatDrawer } from '@angular/material/sidenav';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { of, Subscription, switchMap } from 'rxjs';
import { LenderOrganizationsService } from 'src/app/services/lender.organizations.service';
import { ProductsService } from 'src/app/services/products.service';
import { UsersService } from 'src/app/services/users.service';
import { DeleteConfirmationDialogService } from 'src/app/services/delete-confirmation-dialog.service';
import { Router } from '@angular/router';
import { entityExistsValidator } from 'src/app/validators/entity-exists.validator';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { BtnCellRenderer } from '../../components/btn-cell-renderer/btn-cell-renderer.component';

@Component({
    selector: 'app-lender-organization-list',
    templateUrl: './lender-organization-list.component.html',
    styleUrls: ['./lender-organization-list.component.scss'],
    standalone: false
})
export class LenderOrganizationListComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') drawer: MatDrawer;

  isLoading: boolean;
  userRole: Role | undefined;
  productList: ISelect[] = [];
  isEditing = false;
  id: number;
  datePipe = new DatePipe('en-US');
  subscriptions: Subscription[] = [];
  loadingValidator: boolean;
  public rowData$!: any[];

  public form: FormGroup = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'blur',
    }),
    productIds: new FormControl([], { validators: [Validators.required] }),
    description: new FormControl('', { validators: [Validators.required] }),
  });
  public columnDefs: ColDef[] = [
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      floatingFilter: true,
      sortable: true,
    },
    {
      field: 'description',
      flex: 4,
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      floatingFilter: true,
      sortable: true,
    },
    { field: 'code', flex: 2, suppressMenu: true, floatingFilter: true },
  ];

  constructor(
    private service: LenderOrganizationsService,
    private productService: ProductsService,
    private userService: UsersService,
    private deleteDialog: DeleteConfirmationDialogService,
    private router: Router,
  ) {

  }

  ngOnInit() {
    const productSuscription = this.productService.get().subscribe((response: any) => {
      if (!response.isError) {
        this.productList = [...response.data.items];
      } else {
        this.productList = [];
      }
    });
    this.subscriptions.push(productSuscription)

    const userSubscription = this.userService.userRole$.subscribe((role) => {
      this.userRole = role;
    });
    this.subscriptions.push(userSubscription);
  }

  openRightSidebar(data: any = null) {
    if (data) {
      this.id = data.id;
      this.form.controls['name'].setAsyncValidators([]);

      this.service.getById(this.id.toString()).subscribe(res => {
        if(!res.isError) {
          this.form.reset({ name: res.data.name, description: res.data.description});
          this.form.get('productIds')?.setValue(this.productList.filter(p => {
            return res.data.productIds.some((_p:any) => _p == p.id)
          }));

        }
      })

      this.isEditing = true;
    } else {
      this.form.controls['name'].setAsyncValidators(
        [
          entityExistsValidator(this.service, undefined, (isLoading: boolean) => {
            this.loadingValidator = isLoading;
          })
        ]
      );

      this.isEditing = false;
    }
    this.drawer.toggle();
  }


  popupUpdate(data: any) {
    this.getGridData();
  }

  onConfirmation(result: any) {
    if (result.confirmed) {
      this.service.delete(result.id).subscribe(() => {
        this.getGridData();
      });
    }
  }

  onView(data: any) {
    this.router.navigate([`/admin/lenders-list/${data.id}`]);
  }

  close() {
    this.form.reset();
    this.drawer.close();
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      const data = this.form.value;
      data.productIds = data.productIds.map((prod: any) => prod.id);

      if (this.isEditing) {
        this.service
          .update(this.id.toString(), data)
          .subscribe((data) => {
            this.getGridData();
          });
      } else {
        this.service.add(this.form.value).subscribe((data) => {
          this.getGridData();
        });
      }
    }
  }

  isAdmin() {
    return this.userRole == Role.Admin || this.userRole == Role.SystemUser;
  }

  onChangeAvailability(data: any) {
    var deleteSuscription = this.deleteDialog
      .openDeleteConfirmation(
        `This lender organization and all its lenders will be ${data.isActive ? 'disabled' : 'enabled'}.`,
        data.isActive ? 'Disable confirmation' : 'Enable confirmation',
        data.isActive ? 'Disable' : 'Enable'
      )
      .afterClosed()
      .pipe(
        switchMap(dialogClosed => {
          if(dialogClosed)
            return this.service.changeUserAvailability(data.id, !data.isActive);
          else
            return of(false);
        })
      )
      .subscribe((deleteConfirmation) => {
        if (deleteConfirmation) {
          this.getGridData();
        }
      });

    this.subscriptions.push(deleteSuscription);
  }
  //-------------GRID FUNCTIONS

  onGridReady(params: GridReadyEvent) {
    this.getGridData();
    this.userService.userRole$.subscribe((role) => {
      this.userRole = role;
      this.adjustColumnVisibility(params.api);
    });
  }

  private getGridData() {
    this.service.get({pageSize: 100}).subscribe((r: any) => {
      if (!r.isError) {
        this.rowData$ = r.data.items;
      }
    });
  }

  private adjustColumnVisibility(gridApi: any) {
    let columns = undefined;
    
    if(this.isAdmin())
    {
      const actionCol = {
        field: 'action',
        minWidth: 130,
        maxWidth: 140,
        filter: false,
        sortable: false,
        cellRenderer: BtnCellRenderer,
        cellRendererParams: {
          onEdit: this.openRightSidebar.bind(this),
          onView: this.onView.bind(this),
          onChangeAvailability: this.onChangeAvailability.bind(this),
        },
      };

      columns = [...this.columnDefs, actionCol];
    }
    else{
      columns = this.columnDefs;
    }

    gridApi.setColumnDefs(columns);
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(s => s.unsubscribe());
  }
}
