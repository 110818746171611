<div class="upload-container"
     (dragover)="onDragOver($event)"
     (drop)="onDrop($event)">

  <img *ngIf="imageUrl" [src]="imageUrl" alt="Profile Image" class="profile-preview">

  <input type="file" id="fileInput" (change)="onFileSelected($event)" accept="image/*" hidden>

  <label for="fileInput" class="upload-btn mat-raised-button mat-primary">
    <mat-icon>cloud_upload</mat-icon>
  </label>

</div>
