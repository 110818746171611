import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusBadgeComponent } from '../../status-badge/status-badge.component';
import { ICardListInfo, ICardListStatus } from '../card-item.interface';
import { Router, RouterModule } from '@angular/router';

@Component({
    selector: 'app-card-dashboard-item',
    imports: [CommonModule, RouterModule, StatusBadgeComponent],
    templateUrl: './card-dashboard-item.component.html',
    styleUrls: ['./card-dashboard-item.component.scss']
})
export class CardDashboardItemComponent {
  @Input() infoContent: ICardListInfo[];
  @Input() statusContent: ICardListStatus[];
  @Input() flex: 'half' | '3-2' | '4-1';
  @Input() badgeSize: 'small' | 'large';
  @Output() goToDetail:EventEmitter<any> = new EventEmitter();


  goDetail() {
    this.goToDetail.emit()
  }

  getExtraCount(): string {
    return `+${this.statusContent.length - 3}`;
  }

  isArray(value:any) {
    return Array.isArray(value);
  }
}
