import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-profile-image-upload',
  templateUrl: './profile-image-upload.component.html',
  styleUrls: ['./profile-image-upload.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule]
})
export class ProfileImageUploadComponent implements OnChanges {
  @Input() initialImage: string | null = null;
  @Output() imageSelect: EventEmitter<File> = new EventEmitter();

  imageUrl: string | ArrayBuffer | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialImage'] && this.initialImage) {
      this.imageUrl = this.initialImage;
    }
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => this.imageUrl = reader.result;
      reader.readAsDataURL(file);
      this.imageSelect.emit(file)
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }
}
