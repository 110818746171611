import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InitialsPipe } from '../../../pipes/initials.pipe';
import { AvatarProfileComponent } from '../../avatar-profile/avatar-profile.component';
import { ImageRendererComponent } from '../../image-renderer/image-renderer.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-notification-menu-item',
    templateUrl: './notification-menu-item.html',
    styleUrls: ['./notification-menu-item.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, RouterModule]
})
export class NotificationMenuItemComponent  {
  @Input() title: string;
  @Input() description: string;
  @Input() date: string | null;
  @Input() time: string | null;

}
