import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { APIResponseModel } from '../models/api-response-model';
import { removeUndefinedFormsAttributes } from '../utils/utils';
import { BaseService } from './base-service';
import { INavigation } from './form-submissions.service';
import { ISearchFilter } from './search.service';

export interface IFormConsolidationSearchParameters extends ISearchFilter{
  sortColumn?: string,
  sortOrder?: 'asc' | 'desc',
  backUrl?: string,
}

@Injectable({
  providedIn: 'root'
})
export class FormConsolidationsService extends BaseService {
  private localStorageKey = 'formConsolidation_searchParameters';
  private formConsolidationFiltersForNavigation$: BehaviorSubject<IFormConsolidationSearchParameters>;

  constructor(http: HttpClient) {
    super(http, `formconsolidations`);
    const savedState = localStorage.getItem(this.localStorageKey);
    this.formConsolidationFiltersForNavigation$ = new BehaviorSubject<IFormConsolidationSearchParameters>(
      savedState ? JSON.parse(savedState) : this.getDefaultState()
    );
  }

  private getDefaultState(): IFormConsolidationSearchParameters {
    return {
      sortColumn: 'submissionDate',
      sortOrder: 'desc',
    };
  }

  getFormConsolidationFiltersForNavigation() {
    return this.formConsolidationFiltersForNavigation$.asObservable();
  }

  getFormConsolidationFiltersForNavigationValue(){
    return this.formConsolidationFiltersForNavigation$.getValue();
  }

  setFormConsolidationFiltersForNavigation(newState: Partial<IFormConsolidationSearchParameters>) {
    removeUndefinedFormsAttributes(newState);
    this.formConsolidationFiltersForNavigation$.next(newState);
    localStorage.setItem(this.localStorageKey, JSON.stringify(newState));
  }

  deleteFormConsolidationFiltersForNavigation(){
    localStorage.removeItem(this.localStorageKey);
  }

  uploadDocument(id: string, file: File): Observable<APIResponseModel<any>> {

    let formData = new FormData();
    formData.append(file.name, file);

    return this.http.post<APIResponseModel<any>>(`${this.baseUrl}/documentupload/${id}`, formData)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }

  getComments(id: any): Observable<APIResponseModel<any[]>> {
    return this.http.get<APIResponseModel<any[]>>(`${this.baseUrl}/comments/${id}`)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, []);
        })
    );
  }

  updateStatus(id: string, action: string, data: any): Observable<APIResponseModel<any>> {

    return this.http.post<APIResponseModel<any>>(`${this.baseUrl}/${id}/${action}`, data)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }

  replyComment(
    formId: string,
    commentId: string = '',
    data: any
  ): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/${formId}/comments/${commentId}/reply`, data)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  getNavigation(formId: number, filters: any): Observable<APIResponseModel<INavigation>> {
    return this.http
      .get<APIResponseModel<INavigation>>(`${this.baseUrl}/${formId}/navigation`, filters ? { params: {...filters } } : { params: {} })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, []);
        })
      );
  }

}
