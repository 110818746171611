import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationService } from 'src/app/services/notifications.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community';
import { BtnCellRenderer } from '../../components/btn-cell-renderer/btn-cell-renderer.component';
@Component({
    selector: 'app-mbr-request-list',
    templateUrl: './notifications-list.component.html',
    styleUrls: ['./notifications-list.component.scss'],
    standalone: false
})
export class NotificationsListComponent {
  isLoading: boolean = false;
  submissionService: any;
  countSelected = 0;
  selectedRowIds: number[] = [];
  public rowData$!: any[];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  public columnDefs: ColDef[] = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex:2,
      filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true
    },
    {
      field: 'description', headerName: 'Description', flex:4, filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true
    },
    {
      field: 'action',
      width:150,
      filter: false,
      sortable:false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onView: this.onView.bind(this),
      },
    },
  ];


  constructor(private service: NotificationService, private router: Router) {}

  onView(data: any) {
    this.service.markAsRead([data.id]).subscribe((_) => {
      this.service.notifyBellToUpdate();
      let url = this.service.navigate(data.entity, data.referenceId);
      this.router.navigate([url]);
    });
  }


  onGridReady(params: GridReadyEvent) {
    this.getGridData();
  }

  private getGridData() {
    this.service.get({ pageSize: 100}).subscribe((r: any) => {
      this.rowData$ = r.data.items;
    });
  }

  onSelection(event: RowSelectedEvent) {
    this.countSelected = event.api.getSelectedRows().length;
    this.selectedRowIds = event.api.getSelectedRows().map(x => x.id);
  }

  onMarkAsRead() {
    this.service.markAsRead(this.selectedRowIds).subscribe(_=> {
      this.getGridData();
      this.countSelected = 0;

      this.service.notifyBellToUpdate();
    })
  }
}
