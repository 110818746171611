import { Component, EventEmitter, Injector, Input,  OnInit,  Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UploadDocumentsComponent } from '../upload-documents/upload-documents.component';
import { FormSubmissionsService } from 'src/app/services/form-submissions.service';
import { FormConsolidationsService } from 'src/app/services/form-consolidations.service';
import { MBRRequestsService } from 'src/app/services/mbr-requests.service';

@Component({
    selector: 'app-reject-box',
    templateUrl: './reject-box.component.html',
    styleUrls: ['./reject-box.component.scss'],
    standalone: false
})
export class RejectBoxComponent implements OnInit {

  isLoading: boolean = false;
  private service: FormSubmissionsService | FormConsolidationsService | MBRRequestsService;

  form: FormGroup = new FormGroup({
    comments: new FormControl('', [Validators.required])
  });

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    if (this.type === 'consolidation') {
      this.service = this.injector.get(FormConsolidationsService);
    } else if (this.type === 'mbr-request') {
      this.service = this.injector.get(MBRRequestsService);
    }
  }

  @Input() entityId: string;
  @Input() hide: boolean;
  @Output() actionResult = new EventEmitter<any>();
  @Input() type: string;

  uploadingEventHandler(status: boolean): void {
    this.isLoading = status;
  }


  onSubmit() {

    this.isLoading = true;

    let data = this.form.value;
    data['documents'] = this.documentUploader.uploadedFiles();

    this.service.updateStatus(this.entityId, 'reject', data)
      .subscribe(r => {
        this.isLoading = false;
        this.form.reset();
        this.actionResult.emit({ result: true});
      });

  }

  onCancel() {
    this.form.reset();
    this.actionResult.emit({ result: false });
  }

  @ViewChild(UploadDocumentsComponent) documentUploader: UploadDocumentsComponent;
}
