import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Role } from 'src/app/models/user/role.model';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { of, Subscription, switchMap } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';
import { DeleteConfirmationDialogService } from 'src/app/services/delete-confirmation-dialog.service';
import { Router } from '@angular/router';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AdministratorService } from 'src/app/services/administrator.service';
import { RightSidebarContainerComponent } from '../../components/right-sidebar-container/right-sidebar-container.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { BtnCellRenderer } from '../../components/btn-cell-renderer/btn-cell-renderer.component';
import { AgGridWrapperComponent } from '../../components/ag-grid-wrapper/ag-grid-wrapper.component';

@Component({
  selector: 'app-administrator-list',
  templateUrl: './administrator-list.component.html',
  styleUrls: ['./administrator-list.component.scss'],
  imports: [
    CommonModule,
    MatSidenavModule,
    RightSidebarContainerComponent,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    AgGridWrapperComponent
  ],
  standalone: true,
})
export class AdministratorListComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  userRole: Role | undefined;
  public rowData$!: any[];
  @ViewChild('drawer') drawer: MatDrawer;
  isEditing = false;
  id: number;

  public form: FormGroup = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'blur',
    }),
    description: new FormControl('', { validators: [Validators.required] }),
  });

  subscriptions: Subscription[] = [];

  public columnDefs: ColDef[] = [
      {
        field: 'name',
        flex: 2,
        filter: 'agTextColumnFilter',
        suppressMenu: true,
        floatingFilter: true,
        sortable: true,
      },
      {
        field: 'description',
        flex: 4,
        filter: 'agTextColumnFilter',
        suppressMenu: true,
        floatingFilter: true,
        sortable: true,
      },
    ];

  constructor(
    private service: AdministratorService,
    private userService: UsersService,
    private deleteDialog: DeleteConfirmationDialogService,
    private router: Router
  ) {}

  ngOnInit() {
    const userSubscription = this.userService.userRole$.subscribe((role) => {
      this.userRole = role;
    });
    this.subscriptions.push(userSubscription);
  }

  openRightSidebar(data: any = null) {
    if (data) {
      this.id = data.id;
      this.form.get('name')?.setValue(data.name);
      this.form.get('description')?.setValue(data.description);

      this.isEditing = true;
    } else {
      this.form.get('name')?.setValue('');
      this.form.get('description')?.setValue('');
      this.isEditing = false;
    }
    this.drawer.toggle();
  }

  onConfirmation(result: any) {
    if (result.confirmed) {
      this.service.delete(result.id).subscribe(() => {
        this.getGridData();
      });
    }
  }

  close() {
    this.form.reset();
    this.drawer.close();
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      const data = this.form.value;

      if (this.isEditing) {
        this.service.update(this.id.toString(), data).subscribe((data) => {
          this.getGridData();
        });
      } else {
        this.service.add(this.form.value).subscribe((data) => {
          this.getGridData();
        });
      }
    }
  }

  isAdmin() {
    return this.userRole == Role.Admin || this.userRole == Role.SystemUser;
  }

  onChangeAvailability(data: any) {
    var deleteSuscription = this.deleteDialog
      .openDeleteConfirmation(
        `This administrator will be ${data.isActive ? 'disabled' : 'enabled'}.`,
        data.isActive ? 'Disable confirmation' : 'Enable confirmation',
        data.isActive ? 'Disable' : 'Enable'
      )
      .afterClosed()
      .pipe(
        switchMap((dialogClosed) => {
          if (dialogClosed)
            return this.service.changeUserAvailability(data.id, !data.isActive);
          else return of(false);
        })
      )
      .subscribe((deleteConfirmation) => {
        if (deleteConfirmation) {
          this.getGridData();
        }
      });

    this.subscriptions.push(deleteSuscription);
  }

  onGridReady(params: GridReadyEvent) {
    this.getGridData();
    this.userService.userRole$.subscribe((role) => {
      this.userRole = role;
      this.adjustColumnVisibility(params.api);
    });
  }

  private getGridData() {
    this.service.get({ pageSize: 100 }).subscribe((r: any) => {
      if (!r.isError) {
        this.rowData$ = r.data.items;
      }
    });
  }

  private adjustColumnVisibility(gridApi: any) {
    let columns = undefined;

    if (this.isAdmin()) {
      const actionCol = {
        field: 'action',
        minWidth: 130,
        maxWidth: 140,
        filter: false,
        sortable: false,
        cellRenderer: BtnCellRenderer,
        cellRendererParams: {
          onEdit: this.openRightSidebar.bind(this),
          onChangeAvailability: this.onChangeAvailability.bind(this),
        },
      };

      columns = [...this.columnDefs, actionCol];
    } else {
      columns = this.columnDefs;
    }

    gridApi.setColumnDefs(columns);
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((s) => s.unsubscribe());
  }
}
