import { Component, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
    selector: 'app-document-badge',
    imports: [CommonModule, MatIconModule],
    templateUrl: './document-badge.component.html',
    styleUrls: ['./document-badge.component.scss']
})
export class DocumentBadgeComponent {
  @Input() name: string;
  @Input() serverFileName: string;

  constructor(public  documentService: DocumentsService) {

    
  }

  onDocumentClicked() {
    this.documentService.downloadDocument({
      name: this.name,
      serverFileName: this.serverFileName
    })
  }
}
