<div class="avatar-profile-container">
  <div class="fullname-initials" *ngIf="!imgProfile" [ngClass]="config?.avatarSize">
    <span>{{ fullName | initials | uppercase}}</span>

  </div>
  <img class="user-profile-img" *ngIf="imgProfile" [src]="imgProfile" >
  <div class="profile-content">
    <h4>{{ fullName }}</h4>
    <h5 *ngIf="email"> {{ email }}</h5>
  </div>
</div>
