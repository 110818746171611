import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base-service';
import { AuthService } from '@auth0/auth0-angular';
import { UsersService } from './users.service';
import { Role } from '../models/user/role.model';
import { TypeOfResult } from '../models/typeOfResult/typeOfResult';
import { APIResponseModel } from '../models/api-response-model';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  userRole: Role | undefined;
  reloadBellNotifications: Subject<boolean> = new Subject()

  constructor(
    http: HttpClient,
    private userService: UsersService,
    private auth: AuthService
  ) {
    super(http, `notifications`);

    this.userService.userRole$.subscribe((role) => {
      this.userRole = role;
    });
  }

  notifyBellToUpdate() {
    this.reloadBellNotifications.next(true);
  }

  markAsRead(data: any) {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/read`, data, {
        observe: 'response',
      })
  }

  navigate(entity: any, referenceId: any) {
    let url = '';

    if (this.userRole == Role.Admin) url = '/admin';
    else if (this.userRole == Role.Provider) url = '/provider';
    else url = '/lender';

    console.log(this.userRole, entity);

    if (entity == TypeOfResult.FormSubmission) {
      url += `/form-submission/details/${referenceId}`;
    } else if (entity == TypeOfResult.FormConsolidation) {
      url += `/form-consolidation/details/${referenceId}`;
    } else if (entity == TypeOfResult.MarketingMaterial) {
      url += `/marketing-material/details/${referenceId}`;
    } else if (entity == TypeOfResult.MBRRequest) {
      url += `/mbr-request/details/${referenceId}`;
    } else if (entity == TypeOfResult.ProviderOrganizationDocument){
      url += `/organization`
    }
    else {
      url += `/external-approval/${referenceId}`;
    }

    return url;
  }

  getLimited(pageSize: number = 5): Observable<APIResponseModel<any[]>> {
    return this.http
      .get<APIResponseModel<any[]>>(
        `${this.baseUrl}`,
        pageSize ? { params: {...{ pageSize } } } : { params: {} }
      )
  }
}
