<div class="sidebar-menu">
  <ul class="menu-list">
    <li *ngFor="let userNavRoute of userNavRoutes$ | async; trackByFn">
      <app-nav-item
        [route]="userNavRoute.path"
        [text]="userNavRoute.label"
        [collapseId]="getCollapseId(userNavRoute)"
        [parentActive]="mustActivateParent(userNavRoute)"
        (click)="toggleMenu(userNavRoute.id)"
      >
        <span
          class="icon"
          [ngClass]="{
            'material-icons-outlined': userNavRoute.icon?.type == null,
            'material-icons': userNavRoute.icon?.type == 'solid'
          }"
        >
          {{ userNavRoute.icon?.name }}
        </span>
      </app-nav-item>
      <div
        *ngIf="userNavRoute.children"
        class="submenu"
        [class.collapsed]="!expandedMenus.includes(userNavRoute.id)"
        [id]="getCollapseId(userNavRoute)"
      >
        <ul class="btn-toggle-nav">
          <li *ngFor="let child of userNavRoute.children; trackByFn">
            <app-nav-item
              [route]="child.path"
              [text]="child.label"
              [isChild]="true"
            ></app-nav-item>
          </li>
        </ul>
      </div>
    </li>
  </ul>

  <div class="sidebar-footer">
    <button class="report-btn" (click)="openReportDialog()">
      <span class="material-icons-outlined icon">report</span>
      <span class="report-text">Report a Problem</span>
    </button>
  </div>
</div>
<ng-template #templateReportContent>
  <div class="report-container">
    <p>
      Help us improve by reporting any issues or bugs you encounter while using
      our platform. Please describe the issue you're experiencing below.
    </p>

    <form [formGroup]="form" class="report-content">
      <div class="custom-form-field">
        <label for="title">Title<span class="required">*</span></label>
        <mat-form-field appearance="outline" class="custom-field">
          <input formControlName="title" matInput name="title" />
        </mat-form-field>
      </div>
      <div class="custom-form-field">
        <label for="description">Description<span class="required">*</span></label>
        <mat-form-field appearance="outline" class="custom-field textarea">
          <textarea
            formControlName="description"
            matInput
            name="description"
            rows="3"
          ></textarea>
        </mat-form-field>
      </div>
    </form>
    <p>
      Our team will review your report and work diligently to resolve the issue.
      Thank you for helping us improve!
    </p>
    <p class="italic mt-3">
      If you need immediate assistance, please call us at 561-703-6111.
    </p>
  </div>
</ng-template>
<ng-template #templateReportActions>
  <button
    mat-flat-button
    color="primary"
    type="button"
    [disabled]="form.invalid"
    (click)="onSubmitReport()"
  >
    Report Problem
  </button>
</ng-template>
