import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';


@Component({
    selector: 'app-empty-data',
    imports: [CommonModule],
    templateUrl: './empty-data.component.html',
    styleUrls: ['./empty-data.component.scss']
})
export class EmptyDataComponent  {

}
