import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { APIResponseModel } from '../models/api-response-model';
import { catchError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdministratorService extends BaseService {

  private apiUrl = `administrators`;
  private httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(http, `administrators`);
  }

    changeUserAvailability(id: string, enabled: boolean): Observable<APIResponseModel<any>> {
      return this.http
        .post<APIResponseModel<any>>(`${this.baseUrl}/${id}/change-availability`, { enable: enabled })
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.httpErrorHandler(err, {});
          })
        );
    }
}

