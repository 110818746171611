import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { of, Subscription, switchMap } from 'rxjs';
import { DeleteConfirmationDialogService } from 'src/app/services/delete-confirmation-dialog.service';
import { ProviderInvoicesService } from 'src/app/services/provider-invoices.service';
import { UploadDocumentsComponent } from '../../../components/upload-documents/upload-documents.component';
import { DocumentsService } from 'src/app/services/documents.service';
import { DocumentModel, UPLOADSTATUS } from 'src/app/models/document/document.model';
import { Invoice } from './provider-invoice.model';
import { Role } from 'src/app/models/user/role.model';

@Component({
  selector: 'app-provider-invoices',
  templateUrl: './provider-invoices.component.html',
  styleUrls: ['./provider-invoices.component.scss'],
  standalone: false,
})
export class ProviderInvoicesComponent implements OnInit, OnDestroy {
  @Input() organizationId: string;
  @Input() role: Role | undefined;
  @Input() pageSettings: {
    defaultPageSize: number;
    pageSizeOptions: number[];
  } = {
    defaultPageSize: 20,
    pageSizeOptions: [20, 50, 100],
  };
  @ViewChild('drawer') drawer: MatDrawer;

  public isLoading: boolean = false;

  public currentPage: number = 0;
  public pageSize: number = this.pageSettings.defaultPageSize;

  totalCount: number = 0;

  public providerInvoices: Invoice[] = [];

  datePipe = new DatePipe('en-US');

  isEditing: boolean;

  invoiceForm = new FormGroup({
    invoiceDate: new FormControl('', [Validators.required]),
  });

  documentUploaded: boolean;
  @ViewChild('invoiceDocuments')
  invoiceDocumentUploader: UploadDocumentsComponent;

  invoiceToUpdate: Invoice | null = null;
  filesToUpdate: any[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private providerInvoicesService: ProviderInvoicesService,
    private deleteDialog: DeleteConfirmationDialogService,
    public documentService: DocumentsService
  ) {}

  ngOnInit(): void {
    this.loadProviderInvoices();
  }

  private loadProviderInvoices() {
    this.isLoading = true;
    const subscriptionInvoices = this.providerInvoicesService
      .get({
        providerOrganizationId: this.organizationId,
        page: this.currentPage + 1,
        pageSize: this.pageSize,
      })
      .subscribe((response: any) => {
        this.isLoading = false;
        if (!response.isError) {
          this.providerInvoices = response.data.items;
          this.totalCount = response.data.totalCount;
        }
      });

      this.subscriptions.push(subscriptionInvoices);
  }


  deleteInvoice(data: any) {
    const deleteSub = this.deleteDialog
      .openDeleteConfirmation(
        `This invoice will be deleted.`,
        'Delete confirmation',
        'Delete'
      )
      .afterClosed()
      .pipe(
        switchMap((dialogClosed) => {
          if (dialogClosed) return this.providerInvoicesService.delete(data.id);
          else return of(false);
        })
      )
      .subscribe((deleteConfirmation: any) => {

        if (deleteConfirmation) {
          this.loadProviderInvoices();
        }
      });

      this.subscriptions.push(deleteSub);
  }

  markAsPaid(data: Invoice) {
    const markSub = this.deleteDialog
      .openDeleteConfirmation(
        `This invoice will be mark as ${data.status == 'Paid' ? 'Unpaid' : 'Paid'}.`,
        `Mark ${data.status == 'Paid' ? 'Unpaid' : 'Paid'}  confirmation`,
        'Update'
      )
      .afterClosed()
      .pipe(
        switchMap((dialogClosed) => {
          if (dialogClosed) return this.providerInvoicesService.markAsPaid(data.id);
          else return of(false);
        })
      )
      .subscribe((markConfirmation: any) => {
        if (markConfirmation) {
          this.loadProviderInvoices();
        }
      });

      this.subscriptions.push(markSub);
  }

  uploadInvoice(data: any) {
    this.invoiceToUpdate = data;

    const document =  new DocumentModel(
      new File([new Blob], data.document.name),
      UPLOADSTATUS.SUCCESS
    );

    document.serverFileName = data.document.serverFileName;
    this.filesToUpdate = [document];
    this.documentUploaded = true;

    this.invoiceForm.get('invoiceDate')?.setValue(this.invoiceToUpdate!.invoiceDate);
    this.openRightSidebar(true)
  }

  closeRightSidebar() {
    this.resetFormValues();
    this.invoiceToUpdate = null;
    this.filesToUpdate = [];
    this.drawer.close();
  }

  openRightSidebar(isEditing: boolean) {
    this.isEditing = isEditing;
    this.drawer.toggle();
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;

    this.loadProviderInvoices();
  }

  hasDocumentsUploaded(): boolean {
    return !!this.documentUploaded;
  }

  uploadingEventHandler(status: boolean): void {
    if (status === false) {
      const uploadedFiles = this.invoiceDocumentUploader.uploadedFiles();
      this.documentUploaded = uploadedFiles.length > 0;
    }
  }

  onFileRemoveHandler(filesRemoved: number): void {
    if (filesRemoved == 0) {
      this.documentUploaded = false;
    }
  }

  emptyAttachment() {
    return !this.hasDocumentsUploaded();
  }

  onSubmit() {
    if(this.invoiceForm.valid && !this.emptyAttachment()) {
      const { invoiceDate } = this.invoiceForm.value;
      const document = this.invoiceDocumentUploader.uploadedFiles()[0];
      const data = {
        providerOrganizationId: this.organizationId,
        invoiceDate: this.datePipe.transform(invoiceDate, 'yyyy-MM-dd'),
        document }
      this.isLoading = true;
      if(this.isEditing){
        this.providerInvoicesService
        .update(`${this.invoiceToUpdate ? this.invoiceToUpdate.id : 0}`, data)
        .subscribe(response => {
          this.isLoading = false;
          if(response && response.isError){
            return;
          }
          this.closeRightSidebar();
          this.loadProviderInvoices();
        });
      }
      else{

        this.providerInvoicesService
        .add(data)
        .subscribe(response => {
          this.isLoading = false;
          if(!response.isError){
            this.closeRightSidebar();
            this.loadProviderInvoices();
          }
        });
      }
    }

  }

  downloadDocument(document: any){
    this.documentService.downloadDocument({
      name: document.name,
      serverFileName: document.serverFileName
    })
  }

  resetFormValues() {
    this.invoiceForm.reset();
    this.invoiceDocumentUploader.clearFiles();
  }

  isAdmin() {
    return this.role == Role.Admin;
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(s => s.unsubscribe());
  }
}
