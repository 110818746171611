import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DocumentsService } from 'src/app/services/documents.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { BypassHtmlSanitizerPipe } from '../../../pipes/bypassHtmlSanitizer.pipe';
import { InitialsPipe } from '../../../pipes/initials.pipe';
import { IAttachmentDetail } from '../../attachment-detail/attachment-detail.interface';
import { IDetailComment } from '../detail-comment.interface';
import { TimeAgoPipe } from "../../../pipes/timeAgo.pipe";


@Component({
    selector: 'app-thread-comment',
    templateUrl: './thread-comment.component.html',
    styleUrls: ['./thread-comment.component.scss'],
    imports: [CommonModule, InitialsPipe, MatIconModule, MatButtonModule, BypassHtmlSanitizerPipe, MatDividerModule, TimeAgoPipe]
})
export class ThreadCommentComponent {
  @Input() reply: IDetailComment;

  constructor(public documentService: DocumentsService){}

  downloadDocument(event: Event | null, document: IAttachmentDetail){
    if(event) {
      event.stopPropagation();
    }
    this.documentService.downloadDocument({
      name: document.name,
      serverFileName: document.serverFileName
    })
  }

  downloadAll(event: Event) {
    event.stopPropagation();

    if(this.reply?.documents) {
      this.reply?.documents.forEach(att=>{
        this.downloadDocument(null, att)
      })
    }
  }

  hasDocuments() {
    return this.reply?.documents && this.reply.documents.length > 0;
  }

  getCountAnswersText() {
    let count = this.reply?.replies?.length;
    return `${count} answer${count !== 1 ? 's' : ''}`
  }

  hasReplies() {
    return (this.reply?.replies || [])?.length > 0
  }
}
