import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { BaseService } from 'src/app/services/base-service';

@Component({
    selector: 'app-select-dropdown',
    templateUrl: './select-dropdown.component.html',
    styleUrls: ['./select-dropdown.component.scss'],
    standalone: false
})
export class SelectDropdownComponent implements OnInit {

  private service: BaseService;
  dataSource: any[];
  public formControl: FormControl;

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit(): void {
    this.formControl = this.control as FormControl;
    this.loadData(this.defaultFilters);
  }

  public loadData(filters: any | null = null) {
    if (this.serviceUrl) {
      this.service = new BaseService(this.httpClient, this.serviceUrl);
      this.service.get(filters).subscribe(res => {
        if(!res.isError) {
          this.dataSource = res.data;
          const value = this.dataSource.find(data => data[this.valueField] === this.defaultValue);
          if(value){
            this.control.setValue(this.defaultValue);
          }
        }
      });
    }

  }


  getFormControl(): FormControl {
    // return this.rootForm.control.get(this.controlName) as FormControl;
    return this.control as FormControl;
  }

  getValue(item: any) {
    return item[this.valueField];
  }

  getDisplayName(item: any) {
    return item[this.displayField];
  }

  @Input() serviceUrl: string;
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() valueField: string;
  @Input() displayField: string;
  @Input() disabled: boolean;

  @Input() defaultFilters: any | null;
  @Input() defaultValue: any | null;
}
