<mat-drawer-container hasBackdrop>
  <mat-drawer autoFocus="false"
              #drawer
              mode="over"
              position="end"
              (closed)="close()">
  </mat-drawer>
  <mat-drawer-content>
    <div class="pcs-container">
      <div class="pcs-header">
        <h3 class="pcs-title"
            *ngIf="product">{{ product.name }}</h3>
        <div class="card-actions">
          <a routerLink="/admin/mbr-request/create"
             [queryParams]="{ productId: productId, lenderId: lenderId }"
             mat-flat-button
             color="primary"
             type="button"
             class="btn btn-primary"><mat-icon>add</mat-icon> Request MBR</a>
        </div>
      </div>
      <div class="upload-container">
        <div class="upload-container-body">
          <p class="title">Upload MBR</p>
          <p>This document will be marked as default, and you can then modify that in the table below.</p>
          <app-upload-documents [isMulti]="false"
                                (onFileRemove)="fileRemoved($event)"
                                (uploadingEvent)="uploadingEvent($event)" />
        </div>
        <div class="text-end">
          <button (click)="onSubmit()"
                  [disabled]="!canUpload"
                  mat-flat-button
                  color="primary"
                  type="button"
                  class="btn btn-primary">
            Upload MBR Document
          </button>
        </div>
      </div>
      <div class="custom-form-field">
        <mat-form-field class="search-field custom-field no-hint"
                        appearance="outline">
          <input type="text"
                 placeholder="Search"
                 aria-label="Search"
                 matInput
                 [formControl]="searchControl" />
          <mat-icon color="primary"
                    matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <ng-container *ngIf="isLoading; else templateGrid">
        <div class="loader-container">
          <app-loader />
        </div>
      </ng-container>
      <ng-template #templateGrid>
        <app-card-grid-item *ngFor="let data of dataToShow"
                            [id]="data.id"
                            [title]="data.title || ''"
                            [infoContent]="data.list ? data.list : []"
                            [statusContent]="data.status ? data.status : []"
                            [documentContent]="data.documents"
                            [actionList]="data.actions"
                            (actionClicked)="onActionClicked($event)">
          <div *ngIf="data.isCurrent"
               title-tag
               class="progress-badge primary">
            <span class="custom-text">
              Default
            </span>
          </div>
        </app-card-grid-item>
        <mat-paginator #paginator
                       [selectConfig]="{panelClass: 'mat-paginator-select-options'}"
                       [length]="dataProductMbrs.length"
                       [pageSize]="pageSettings.defaultPageSize"
                       [pageSizeOptions]="pageSettings.pageSizeOptions"
                       (page)="handlePageEvent($event)"
                       aria-label="Select page">
        </mat-paginator>
      </ng-template>
    </div>

  </mat-drawer-content>
</mat-drawer-container>