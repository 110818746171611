import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-information-message',
    templateUrl: './information-message.component.html',
    styleUrls: ['./information-message.component.scss'],
    standalone: false
})
export class InformationMessageComponent {
  @Input() messageTitle: string;
  @Input() messageBody: string;
}
