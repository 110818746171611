
<div *ngFor="let doc of attachments" class="submitted-attachment" (click)="downloadFile(doc)">
  <mat-icon fontSet="material-icons-outlined">
      insert_drive_file
  </mat-icon>
  <span>{{doc.name}}</span>
</div>

<div *ngIf="attachments.length == 0">
    <span>N/A</span>
</div>
