<mat-drawer-container hasBackdrop>
    <mat-drawer autoFocus="false" #drawer mode="over" position="end" (closed)="close()">
      <app-right-sidebar-container
        [title]="(isEditing ? 'Update' : 'Create') + ' Administrator'"
        [footerSubmitCustomLabel]="isEditing ? 'Update' : 'Create'"
        (cancel)="close()"
        (submit)="onSubmit()"
        [disableSubmit]="form.invalid"
      >
        <form [formGroup]="form" class="provider-content">
          <div class="custom-form-field">
            <label for="lenderIds">Administrator Name<span class="required">*</span></label>
            <mat-form-field appearance="outline" class="custom-field">
              <input formControlName="name" matInput name="name" />
              <mat-error *ngIf="form.controls['name'].errors?.['required']">
                Please insert Administrator name
              </mat-error>
            </mat-form-field>
          </div>
          <div class="custom-form-field">
            <label for="notes">Administrator Description<span class="required">*</span></label>
            <mat-form-field
              appearance="outline"
              class="custom-field textarea no-hint"
            >
              <textarea
                formControlName="description"
                matInput
                name="description"
                rows="3"
              ></textarea>
            </mat-form-field>
            <mat-error
              *ngIf="form.controls['description'].touched && form.controls['description'].errors?.['required']"
            >
              Please enter Organization description
            </mat-error>
          </div>
        </form>
      </app-right-sidebar-container>
    </mat-drawer>
    <mat-drawer-content>
      <div class="pcs-container">
        <div class="pcs-header">
          <h3 class="pcs-title">Administrators</h3>
          <div class="card-actions" *ngIf="isAdmin()">
            <button
              (click)="openRightSidebar()"
              mat-flat-button
              color="primary"
              type="button"
            ><mat-icon>add</mat-icon> Create</button>
          </div>
        </div>
        <div class="pro-card">
          <app-ag-grid-wrapper
            [data]="rowData$"
            [columns]="columnDefs"
            (ready)="onGridReady($event)"
          />
        </div>
      </div>

    </mat-drawer-content>
  </mat-drawer-container>
