<mat-drawer-container hasBackdrop>
    <mat-drawer autoFocus="false" #drawer mode="over" position="end" (closed)="close()">
    </mat-drawer>
    <mat-drawer-content>
      <div class="pcs-container">
        <div class="pcs-header">
          <h3 class="pcs-title">Product MBRs</h3>
          <div class="card-actions">
            <a
              *ngIf="canCreate()"
              (click)="onRequestMBR()"
              mat-flat-button
              color="primary"
              type="button"
            ><mat-icon>add</mat-icon> Request MBR</a>
          </div>
        </div>
        <div class="pro-card">
          <app-ag-grid-wrapper
            [data]="rowData$"
            [columns]="columnDefs"
            (ready)="onGridReady($event)"
          />
        </div>
      </div>

    </mat-drawer-content>
  </mat-drawer-container>
