import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'src/app/models/user/role.model';
import { MBRDocumentsService } from 'src/app/services/mbr-documents.service';
import { MatDrawer } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';
import { ColDef, GridReadyEvent, ValueGetterParams } from 'ag-grid-community';
import { BtnCellRenderer } from '../../components/btn-cell-renderer/btn-cell-renderer.component';
import { GridDocumentComponent } from '../../components/grid-document/grid-document.component';

@Component({
    selector: 'app-product-mbr-list',
    templateUrl: './product-mbr-list.component.html',
    styleUrls: ['./product-mbr-list.component.scss'],
    standalone: false
})
export class ProductMbrListComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  userRole: Role | undefined;;
  @ViewChild('drawer') drawer: MatDrawer;

  public columnDefs: ColDef[] = [
    { field: 'product.name', headerName: 'Product', flex: 3, filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true },
    {
      field: 'mbrDocument.name',
      headerName: 'MBR Document',
      flex:3,
      cellRenderer: GridDocumentComponent,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.mbrDocument ? [params.data.mbrDocument] : [];
      }
    },
    { field: 'mbrDocument.version', headerName: 'Version', flex:3, filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true},
    {
      field: 'action',
      width:150,
      filter: false,
      sortable:false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onView: this.onView.bind(this),
      },
    },
  ];

  subscriptions: Subscription[] = [];
  public rowData$!: any[];

  constructor(
    private mbrService: MBRDocumentsService,
    private userService: UsersService,
    private router: Router,
  ) {

  }

  ngOnInit() {
    const userSubscription = this.userService.userRole$.subscribe((role) => {
      this.userRole = role;

    });
    this.subscriptions.push(userSubscription);

  }

  onRequestMBR(){
    if(this.isAdmin()){
      this.router.navigate(['/admin/mbr-request/create']);
    }
    else{
      this.router.navigate(['/lender/mbr-request/create']);
    }
  }

  onView(data: any) {
    if(this.isAdmin()){
      this.router.navigate([`/admin/product-mbr/${data.product.id}/${data.organization.id}`]);
    }
    else{
      this.router.navigate([`/lender/product-mbr/${data.product.id}`]);
    }
  }

  close() {
    this.drawer.close();
  }

  isAdmin() {
    return this.userRole == Role.Admin || this.userRole == Role.SystemUser;
  }


  canCreate() {
    return this.userRole !== Role.Provider
  }

  onGridReady(params: GridReadyEvent) {
    this.getGridData();
  }

  private getGridData() {
    this.mbrService.get({pageSize: 100}).subscribe((r:any) => {
      this.rowData$ = r.data.items;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(s => s.unsubscribe());
  }
}
