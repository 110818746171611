import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProvidersService } from 'src/app/services/providers.service';
import { StatesService } from 'src/app/services/states.service';
import { ProductsService } from 'src/app/services/products.service';
import { IState } from 'src/app/models/state/state.model';
import { IProduct } from 'src/app/models/product/product.model';
import { IProviderOrganizationDocumentType } from 'src/app/models/provider/provider-license.model';
import { DocumentTypesService } from 'src/app/services/document-types.service';
import { debounceTime, of, Subscription, switchMap, tap } from 'rxjs';
import { Role } from 'src/app/models/user/role.model';
import { DeleteConfirmationDialogService } from 'src/app/services/delete-confirmation-dialog.service';
import { UsersService } from 'src/app/services/users.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { IHeaderAction } from 'src/app/modules/shared/components/header-actions/header-actions.interface';
import { DatePipe } from '@angular/common';
import { ICardListInfoData } from 'src/app/modules/shared/components/cards/card-item.interface';
import { PageEvent } from '@angular/material/paginator';
import { entityExistsValidator } from 'src/app/validators/entity-exists.validator';
import { ProviderOrganizationsService } from 'src/app/services/provider.organizations.service';
import { IData } from 'src/app/modules/shared/components/row-detail-data/row-details-data.component';
import { sortData } from '../../utils';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { BtnCellRenderer } from '../../components/btn-cell-renderer/btn-cell-renderer.component';
@Component({
    selector: 'app-providers-list',
    templateUrl: './providers-list.component.html',
    styleUrls: ['./providers-list.component.scss'],
    standalone: false
})
export class ProvidersListComponent implements OnInit {
  public organizationId: any;
  public states: IState[] = [];
  public products: IProduct[] = [];
  public documentTypes: IProviderOrganizationDocumentType[] = [];

  @ViewChild('drawer') drawer: MatDrawer;
  userRole: Role | undefined;
  isEditing = false;
  isLoading: boolean;
  id: number;

  form: FormGroup = new FormGroup({
    email: new FormControl('', {
      validators: [Validators.email, Validators.required],
      updateOn: 'blur',
    }),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    organizationId: new FormControl('', [Validators.required]),
  });

  pageSettings: {
    defaultPageSize: number;
    pageSizeOptions: number[];
  } = {
    defaultPageSize: 20,
    pageSizeOptions: [20, 50, 100],
  };
  datePipe = new DatePipe('en-US');
  currentPage: number = 0;
  pageSize: number = this.pageSettings.defaultPageSize;
  currentPageInvoices: number = 0;
  pageSizeInvoices: number = this.pageSettings.defaultPageSize;
  dataToShow: any[];
  dataToShowInvoices: any[] = [];

  subscriptions: Subscription[] = [];
  organization: any;
  descriptionRowData: IData[] = [{
    title: 'Description',
    description: '-'
  }];

  public columnDefs: ColDef[] = [
    {
      field: 'firstName',
      flex:2,
      filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true
    },
    { field: 'lastName', flex:2, filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true},
    { field: 'email', flex:3, filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true},
    { field: 'phoneNumber', flex:2, filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true},
  ];
  public rowData$!: any[];

  constructor(
    private providerService: ProvidersService,
    private organizationService: ProviderOrganizationsService,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StatesService,
    private productsService: ProductsService,
    private deleteDialog: DeleteConfirmationDialogService,
    private userService: UsersService,
    private providerDocumentService: DocumentTypesService
  ) {

  }

  ngOnInit(): void {
    this.initOrganization();
    this.getStates();
    this.getProducts();
    this.getDocumentTypes();
  }

  private initOrganization() {
    const userRoleSubscription = this.userService.userRole$.pipe(
          switchMap((role) => {
            this.userRole = role;
            if (role === Role.Provider) {
              return this.userService.organizationId$;
            } else {
              return [this.route.snapshot.paramMap.get('id')];
            }
          })
        )
        .subscribe((id) => {
          this.organizationId = id ? `${id}` : null;
          this.getOrganization();
          this.getGridData();

        });

    this.subscriptions.push(userRoleSubscription);
  }

  onChangeAvailability(data: any) {
    this.deleteDialog
      .openDeleteConfirmation(
        `This provider user will be ${data.isActive ? 'disabled' : 'enabled'}.`,
        data.isActive ? 'Disable confirmation' : 'Enable confirmation',
        data.isActive ? 'Disable' : 'Enable'
      )
      .afterClosed()
      .pipe(
        switchMap((dialogClosed) => {
          if (dialogClosed)
            return this.providerService.changeUserAvailability(
              data.userId,
              !data.isActive
            );
          else return of(false);
        })
      )
      .subscribe((deleteConfirmation) => {
        if (deleteConfirmation) {
          this.getGridData();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((s) => s.unsubscribe());
  }

  private getProducts() {
    this.productsService.get().subscribe((data: any) => {
      if (!data.isError) {
        this.products = data.data.items;
      }
    });
  }

  private getStates() {
    this.stateService.get().subscribe(data => {
      if (!data.isError) {
        this.states = data.data;
      }
    });
  }

  private getDocumentTypes() {
    this.providerDocumentService.get().subscribe(data => {
      if (!data.isError) {
        this.documentTypes = data.data;
      }
    });
  }

  private getOrganization() {
    this.organizationService.getById(this.organizationId).subscribe((data) => {
      if (!data.isError) {
        this.organization = data.data;
        this.descriptionRowData = [
          {
            title: 'Description',
            description: this.organization.description
          },

        ]
      }
    });
  }

  openProviderSidebar(data: any = null) {
    if (data) {
      this.id = data.id;
      this.form.controls['email'].setAsyncValidators([
        entityExistsValidator(this.providerService, data.email),
      ]);
      const getSus = this.providerService
        .getById(this.id.toString())
        .subscribe((res) => {
          if (!res.isError) {
            this.form.reset({
              email: res.data.email,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              phoneNumber: res.data.phoneNumber,
              organizationId: res.data.organizationId,
            });
          }
        });
      this.subscriptions.push(getSus);
      this.isEditing = true;
      this.form.controls['email'].disable();
    } else {
      this.form.controls['organizationId'].setValue(this.organizationId);
      this.form.controls['email'].setAsyncValidators([
        entityExistsValidator(this.providerService),
      ]);
      this.form.controls['email'].enable();
      this.isEditing = false;
    }
    this.form.updateValueAndValidity();
    this.drawer.toggle();
  }

  close() {
    this.form.reset();
    this.drawer.close();
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      const data = this.form.value;

      if (this.isEditing) {
        const updateSuscription = this.providerService
          .update(this.id.toString(), data)
          .subscribe((data) => {
            this.getGridData();
          });
        this.subscriptions.push(updateSuscription);
      } else {
        const addSuscription = this.providerService
          .add(this.form.value)
          .subscribe((data) => {
            this.getGridData();
          });
        this.subscriptions.push(addSuscription);
      }
    }
  }

  isAdmin() {
    return this.userRole == Role.Admin;
  }

  //----------GRID FUNCTIONS
  onGridReady(params: GridReadyEvent) {
    this.getGridData();
    this.userService.userRole$.subscribe((role) => {
      this.userRole = role;
      this.adjustColumnVisibility(params.api);
    });
  }

  private getGridData() {
    this.providerService
      .getByOrganizationId(this.organizationId, {pageSize: 100})
      .subscribe((r: any) => {
        this.rowData$ = r.data.items;
      });
  }

  private adjustColumnVisibility(gridApi: any) {
    const actionCol = {
      field: 'action',
      minWidth: 130,
      maxWidth: 140,
      filter: false,
      sortable: false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onEdit: this.openProviderSidebar.bind(this),
        onChangeAvailability: this.isAdmin() ? this.onChangeAvailability.bind(this) : undefined,
      },
    };

    gridApi.setColumnDefs([...this.columnDefs, actionCol]);

  }
}
