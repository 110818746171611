import { Component, Input } from '@angular/core';
import { IProviderOrganizationDocument, IProviderOrganizationDocumentState, IProviderOrganizationFile } from 'src/app/models/provider/provider-license.model';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
    selector: 'app-provider-document-card',
    templateUrl: './provider-document-card.component.html',
    styleUrls: ['./provider-document-card.component.scss'],
    standalone: false
})
export class ProviderDocumentCardComponent {

  @Input() isLoadingLicenses: boolean = false;
  @Input() providerLicenses: IProviderOrganizationDocument[] = [];
  @Input() licenseActions: {
    icon: string,
    cssClass:string,
    click: (license: IProviderOrganizationDocument) => void
  }[] = [];
  @Input() maxStatesVisible: number = 5;

  constructor(public documentService: DocumentsService) {

  }

  getApplicableStatesToShow(license: IProviderOrganizationDocument): IProviderOrganizationDocumentState[]{
    return license.applicableStates.slice(0, this.maxStatesVisible);
  }

  exceedsMaximumStatesToShow(license: IProviderOrganizationDocument): boolean {
    return license.applicableStates.length > this.maxStatesVisible;
  }

  remainingStatesToShow(license: IProviderOrganizationDocument): number{
    return license.applicableStates.length - this.maxStatesVisible;
  }

  downloadDocument(document: IProviderOrganizationFile){
    this.documentService.downloadDocument({
      name: document.name,
      serverFileName: document.systemName
    })
  }

  isExpired(date: string | Date): boolean {
    const today = new Date();
    const expDate = new Date(date);
    return expDate < today;
  }
}
